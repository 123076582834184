<template>
  <div id="generator" :style="{width: (open ? '100vw' : '0vw')}">
    <div @click="$emit('close-generator')" class="generator-back">
      <p>zurück zum Beitrag</p>
    </div>
    <template v-if="loaded">
      <GeneratorContent :daten="daten" />
      <GeneratorSelect @exportieren="showExport = true" :daten="daten" />
      <GeneratorExport @done="showExport = false" :daten="daten" v-if="showExport" />
    </template>
    <div class="error" v-else-if="error">
      Fehler beim Laden ... versuchen Sie es bitte später noch einmal ...
    </div>
    <div class="loading" v-else>
      Lade ...
    </div>
  </div>
</template>

<script>
import Allgemeines from '../functions/Allgemeines';

export default {
  name: 'Generator',
  props: {
    daten: Object,
    open: Boolean
  },
  data () {
    return {
      loaded: false,
      error: false,
      showExport: false,
      // rUrl: window.location.href === 'http://127.0.0.1:8080/' ? 'http://127.0.0.1:8008/edsa-diagramm-generator/' : '/diagramm-generator-be5/',
      // gUrl: window.location.href === 'http://127.0.0.1:8080/' ? 'http://127.0.0.1:8008/edsa-diagramm-generator/glossar.php?jsdata=1' : window.location.href.indexOf('top10-typo3.de') > -1 ? '/diagramm-generator-be5/glossar.php?jsdata=1' : 'https://www.wissensportal-frankfurtrheinmain.de/webseiten/wir/aktivitaeten/publikationen/glossar/?jsdata=1'
      rUrl: window.location.href === 'http://127.0.0.1:8080/' ? 'http://127.0.0.1:8008/edsa-diagramm-generator/' : '/diagramm-generator-php/',
      gUrl: window.location.href === 'http://127.0.0.1:8080/' ? 'http://127.0.0.1:8008/edsa-diagramm-generator/glossar.php?jsdata=1' : 'https://www.wissensportal-frankfurtrheinmain.de/webseiten/wir/aktivitaeten/publikationen/glossar/?jsdata=1'
    }
  },
  mounted () {
    if (this.open) {
      this.loadData()
    }
    if (!this.daten.mainData) {
      this.$set(this.daten, 'mainData', {
        basis: new Allgemeines.basisWerte(),
        functions: {
          regionen: new Allgemeines.regionen(),
          dataFunctions: new Allgemeines.dataFunctions()
        },
        akt: {
          dateien: {},
          bereiche: {},
          wordReplace: {}
        },
        time: 0
      })
    }
    // console.log('daten', this.daten)
  },
  methods: {
    loadData () {
      if (!this.loaded) {
        this.$http
          .post(this.rUrl + 'data/data.json', {}, { emulateJSON: true })
          .then((response) => {
            console.log('getJsonData')
            this.loadJsonData(response.data)
            this.loaded = true
            this.loadGlossar()
          })
          .catch((err) => {
            console.log(err)
            this.error = true
          })
      }
    },
    loadGlossar () {
      this.daten.glossar.loading = true
      this.$http
        .get(this.gUrl, {}, {})
        .then((response) => {
          console.log('loadGlossar')
          this.daten.glossar.loading = false
          if (response.data) {
            response.data.forEach(glossarEintrag => {
              let aName = glossarEintrag.match(/<h3>([\S\s]+)<\/h3>/gmi)
              if (aName && typeof aName[0] === 'string' && aName[0].length > 9) {
                aName = aName[0].slice(4, -5).trim()
                let aId = aName.toLowerCase().replace(/ /g, '_').replace(/[„“]/g, '')
                this.$set(this.daten.glossar.obj, aId, {
                  org: glossarEintrag,
                  titel: aName,
                  id: aId,
                  content: glossarEintrag.match(/<p>([\S\s]+)<\/p>/gmi).join('').trim()
                })
                // console.log('Glossar Daten', aId, aName)
              }
            })
          }
        })
        .catch((err) => {
          console.log(err)
          this.error = true
          this.daten.glossar.loading = false
        })
    },
    loadJsonData (aData) {
      if (aData && aData.time) {
        this.daten.mainData.time = aData.time
        let sData = {}
        sData.bereiche = aData.overview
        sData.wordReplace = aData.wordReplace
        sData.dateien = {}
        aData.files.forEach(aFile => {
          aFile.content = this.daten.mainData.functions.dataFunctions.renderFile(aFile.content)
          sData.dateien[aFile.id] = aFile
          aFile.loaded = true
        })
        this.$set(this.daten.mainData, 'akt', sData)
      }
    }
  },
  watch: {
    open (nVal) {
      if (nVal) {
        this.loadData()
      }
    }
  },
  components: {
    GeneratorContent: () => import(/* webpackChunkName: "genext" */ './GeneratorContent.vue'),
    GeneratorSelect: () => import(/* webpackChunkName: "genext" */ './GeneratorSelect.vue'),
    GeneratorExport: () => import(/* webpackChunkName: "genext" */ './GeneratorExport.vue'),
  }
}
</script>

<style scoped>
#generator {
  position: absolute;
  right: 0;
  top: 0;
  width: 0%;
  max-width: calc(100vw - 8rem);
  height: 100vh;
  overflow: hidden;
  color: #0099e7;
  background-color: #d9f0fb;
  -webkit-transition: width 1s ease;
  transition: width 1s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  z-index: 100;
}
.generator-back {
  width: 8rem;
  height: 100%;
  padding: 2rem;
  cursor: pointer;
  position: relative;
  border-color: #0099e7;
}
.generator-back:before {
  content: '\e900';
  font-family: WiR-Icons !important;
  position: absolute;
  top: 2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 3rem;
  color: #0099e7;
}
.generator-back p {
  -webkit-transform: rotate(-90deg) translateX(-100%);
  transform: rotate(-90deg) translateX(-100%);
  -webkit-transform-origin: left top 0;
  transform-origin: left top 0;
  font-size: 3rem;
  width: 80vh;
  text-align: right;
  padding-right: 9rem;
  text-transform: uppercase;
}
.loading {
  font-size: 3rem;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 48vh;
}
.error {
  font-size: 2.5rem;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 48vh;
  background: #fbd9d9;
  color: #f00;
}
@media (max-width: 1280px) {
  #generator {
    max-width: 100vw;
  }
  .generator-back {
    width: 5rem;
    padding: 1rem;
  }
  .generator-back p {
    font-size: 2rem;
    width: 90vh;
  }
}
@media (max-width: 700px) {
  .generator-back p {
    display: none;
  }
  .generator-back {
    position: absolute;
    left: 1rem;
    top: 1rem;
    height: 3.5rem;
    width: 3.5rem;
    z-index: 10;
    background-color: #d9f0fb;
  }
  .generator-back:before {
    top: 0.25rem;
    font-size: 2.2rem;
    color: #0099e7;
  }
}
</style>
