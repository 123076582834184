import FrmRegionen from '../data/FrmRegionen';
import Metropolregionen from '../data/Metropolregionen';

const localFunctions = {
  regionen: function () {
    this.init = true
    this.FrmRegionen = FrmRegionen.FrmRegionen()
    this.Metropolregionen = Metropolregionen.Metropolregionen()
  },
  dataFunctions: function () {
    this.init = true
  },
  basisWerte: function () {
    this.farbensets = [
      { a: '#0099e7', b: '#d9f0fb', titel: 'Blau', selectable: true},
      { a: '#50a66c', b: '#e5f2e9', titel: 'Grün', selectable: true},
      { a: '#9d2054', b: '#f0dee6', titel: 'Rot', selectable: true},
      { a: '#5b2283', b: '#e7deed', titel: 'Violett', selectable: true},
      { a: '#004c99', b: '#d9e4f0', titel: 'Dunkelblau', selectable: true},
      { a: '#5c4b45', b: '#e7e4e3', titel: 'Braun', selectable: true},
      { a: '#565656', b: '#C8C8C8', titel: 'Grau', selectable: true},
      { a: '#111111', b: '#565656', titel: 'Schwarz', selectable: true},
      { a: '#6DC5F1', b: '#d9f0fb', titel: 'Hellblau'},
      { a: '#82BF96', b: '#e5f2e9', titel: 'Hellgrün'},
      { a: '#B95F85', b: '#f0dee6', titel: 'Hellrot'},
      { a: '#8A61A6', b: '#e7deed', titel: 'Hellviolett'},
      { a: '#8A7E7A', b: '#e7e4e3', titel: 'Hellbraun'},
      { a: '#8F8F8F', b: '#C8C8C8', titel: 'Hellgrau'},
    ]
  }
}

localFunctions.regionen.prototype.getByVal = function (val, reg) {
  if (reg === 'FrmRegionen') {
    return { name: this.FrmRegionen.byId[val] ? this.FrmRegionen.byId[val].name : val, obj: this.FrmRegionen.byId[val] ? this.FrmRegionen.byId[val] : null }
  }
  if (reg === 'Metropolregionen') {
    let rData = { name: val, obj: null }
    Object.keys(this.Metropolregionen.byId).map( mr => {
      let mrObj = this.Metropolregionen.byId[mr]
      let sVal = typeof val === 'string' ? val.toLocaleLowerCase() : val
      if (mrObj.name.toLowerCase() === sVal) {
        rData = { name: mrObj.name, obj: mrObj }
        return true
      }
    })
    return rData
  }
  return { name: val, obj: null }
}

localFunctions.dataFunctions.prototype.renderFile = function (dObj) {
  // console.log('renderFile', dObj)
  // Daten ermitteln
  if (
    dObj.optionen.datenart === 'Jahresaufstellung' ||
    dObj.optionen.datenart === 'Artenaufstellung' ||
    dObj.optionen.datenart === 'Adressenliste' ||
    dObj.optionen.datenart === 'Adressen mit Koordinaten'
  ) {
    let aSpalten = {}
    let aSpaltenList = []
    for (let aColKey = dObj.optionen.jahr ? 0 : 1; aColKey < dObj.tabelle[0].length; aColKey++) {
      aSpalten[aColKey] = dObj.tabelle[0][aColKey]
      aSpaltenList.push(aSpalten[aColKey])
    }
    if (dObj.optionen.jahr) {
      dObj.jahr = [dObj.optionen.jahr]
    } else {
      if (dObj.optionen.datenart === 'Jahresaufstellung') {
        dObj.jahr = [...(dObj.optionen['mehrere jahre'] ? ['Mehrere Jahre'] : []), ...Object.values(aSpalten).sort()]
      } else if (['Artenaufstellung', 'Adressenliste', 'Adressen mit Koordinaten'].indexOf(dObj.optionen.datenart) > -1) {
        dObj.jahr = [dObj.tabelle[0][0]]
      }
    }
    dObj.daten = {}
    let dataName = 'mainData'
    let noNameRowCount = 0
    for (let aRowKey = 1; aRowKey < dObj.tabelle.length; aRowKey++) {
      let aRow = dObj.tabelle[aRowKey]
      // console.log(aRow, dataName)
      if (aRow.length > 1 && dataName) {
        if (!dObj.daten[dataName]) {
          dObj.daten[dataName] = { list: [], obj: {}, spalten: aSpaltenList, schnitt: { list: [], obj: {} }, summe: { list: [], obj: {} } }
        }
        if (aRow[0]) {
          let aRowData = { name: typeof aRow[0] === 'string' ? aRow[0].trim() : aRow[0], type: null, style: null, spalten: { list: [], obj: {} }}
          if (typeof aRowData.name === 'string') {
            let aMatches = aRowData.name.match(/([/=-]*)((\\[nf])*)$/)
            aRowData.name = aRowData.name.replace(/([/=]*)((\\[nf])*)$/, '')
            if (aMatches && typeof aMatches[1] === 'string' && aMatches[1].length > 0) {
              aRowData.type = aMatches[1] === '=' ? 'summe' : (aMatches[1] === '/' ? 'schnitt' : (aMatches[1] === '-' ? 'zeile' : null))
            }
            if (aMatches && typeof aMatches[2] === 'string' && aMatches[2].length > 1) {
              aRowData.style = aMatches[2] === '\\n' ? 'normal' : (aMatches[2] === '\\f' ? 'bold' : null)
            }
            if (!aRowData.type && (aRowData.name.toLowerCase().indexOf('absolut') > -1 || aRowData.name.toLowerCase().indexOf('summe') > -1 || aRowData.name.toLowerCase().indexOf('gesamt') > -1)) {
              aRowData.type = 'summe'
            }
            if (!aRowData.type && (aRowData.name.toLowerCase().indexOf('schnitt') > -1)) {
              aRowData.type = 'schnitt'
            }
            if (!aRowData.type) {
              aRowData.type = 'zeile'
            }
            // console.log('aRowData', dObj.optionen.id, aRowData)
          }
          if (aRowData.type === 'summe') {
            if (!aRowData.style) {
              aRowData.style = 'bold'
            }
            dObj.daten[dataName].summe.obj[aRow[0]] = aRowData
            dObj.daten[dataName].summe.list.push(aRowData)
          } else if (aRowData.type === 'schnitt') {
            if (!aRowData.style) {
              aRowData.style = typeof aRowData.name === 'string' && (aRowData.name.toLowerCase().indexOf('wissensregion') > -1 || aRowData.name.toLowerCase().indexOf('metropolregion') > -1 || aRowData.name.toLowerCase().indexOf('bund') > -1) ? 'bold' : 'normal'
            }
            dObj.daten[dataName].schnitt.obj[aRow[0]] = aRowData
            dObj.daten[dataName].schnitt.list.push(aRowData)
          } else {
            if (!aRowData.style) {
              aRowData.style = typeof aRowData.name === 'string' && (aRowData.name.toLowerCase().indexOf('wissensregion') > -1 || aRowData.name.toLowerCase().indexOf('frankfurtrheinmain') > -1) ? 'bold' : 'normal'
            }
            dObj.daten[dataName].obj[aRow[0]] = aRowData
            dObj.daten[dataName].list.push(aRowData)
          }
          for (let aColKey = 1; aColKey < dObj.tabelle[0].length; aColKey++) {
            if (aSpalten[aColKey]) {
              aRowData.spalten.obj[aSpalten[aColKey]] = { name: aSpalten[aColKey], val: (typeof aRow[aColKey] === 'string' ? (aRow[aColKey].trim() === '---' ? undefined : aRow[aColKey].trim()) : aRow[aColKey])}
              aRowData.spalten.list.push(aRowData.spalten.obj[aSpalten[aColKey]])
            }
          }
        } else if (aRow[1]) {
          let aRowData = { name: 'nnr-' + noNameRowCount, spalten: { list: [], obj: {} }}
          dObj.daten[dataName].obj[aRowData.name] = aRowData
          dObj.daten[dataName].list.push(aRowData)
          for (let aColKey = 1; aColKey < dObj.tabelle[0].length; aColKey++) {
            if (aSpalten[aColKey]) {
              aRowData.spalten.obj[aSpalten[aColKey]] = { name: aSpalten[aColKey], val: aRow[aColKey]}
              aRowData.spalten.list.push(aRowData.spalten.obj[aSpalten[aColKey]])
            }
          }
          noNameRowCount += 1
        }
      } else {
        dataName = null
        if (aRow.length === 1) {
          dataName = typeof aRow[0] === 'string' ? aRow[0].trim() : aRow[0]
        }
      }
    }
  }
  return dObj
}

export default localFunctions
